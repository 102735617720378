import "./ProductModal.css";
import ReactDOM from "react-dom";
import { IoMdCloseCircle } from "react-icons/io";

const Backdrop = (props) => {
  return <div className="p-backdrop" onClick={props.onClick}></div>;
};

const ModalOverlay = (props) => {
  return (
    <div className="p-modal">
      <div className="content">{props.children}</div>
    </div>
  );
};

const ProductModal = (props) => {
  const portalElement = document.getElementById("overlays");

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClick={props.onExit} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>
          <div className="p-modal-container">
            {props.children}
            <div className="closeBtn" onClick={props.onExit}>
              <IoMdCloseCircle size={28} />
            </div>
          </div>
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default ProductModal;
