import "./Error.css";
import Footer from "../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  const [language] = useOutletContext();
  const isChinese = language === "chinese";
  return (
    <div>
      <div className="error-body">
        <h1>404</h1>
        <h2>{isChinese ? "找不到相關頁面" : "Page Not Found"}</h2>
        <div
          className="back-home"
          onClick={() => {
            navigate("/");
          }}
        >
          {isChinese ? "返回主頁" : "Return to Homepage"}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Error;
