import Modal from "../Modal/Modal.js";
import "./VerifyAge.css";
import { useState } from "react";

const VerifyAge = (props) => {
  const [isUnderage, setIsUnderage] = useState(false);

  const underageHandler = () => {
    setIsUnderage(true);
  };
  return (
    <Modal>
      {}
      <div className="ageholder">
        <div className="age-description">
          <h2>需要年滿21歲方能瀏覽本網站。</h2>
          <p>請確認閣下之年齡。請問閣下是否已年滿21歲 ? </p>
        </div>
        <div className="age-description">
          <h2>You must be over 21 years old to enter: </h2>
          <p>Please confirm your age. Are you over 21 years old?</p>
        </div>

        <div className="age-bottons">
          <div className="button" onClick={props.onConfirm}>
            <p>是 / Yes</p>
          </div>
          <div className="button" onClick={underageHandler}>
            <p>否 / No</p>
          </div>
        </div>
        {isUnderage && (
          <>
            <p className="age-warning">
              *** 閣下需要年滿21歲方可進入本網站. ***
            </p>
            <p className="age-warning">
              *** You need to be at least 21 years old to enter. ***
            </p>
          </>
        )}
      </div>
    </Modal>
  );
};

export default VerifyAge;
