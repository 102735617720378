import "./Footer.css";
import { useOutletContext } from "react-router-dom";

const Footer = () => {
  const [language] = useOutletContext();
  const isChinese = language === "chinese";
  return (
    <div className="footerholder">
      <div className="footer">
        {!isChinese
          ? "Copyright © San Son Wai Cigars Trading Company Limited. All Rights Reserved."
          : " © 新信威煙業貿易有限公司 版權所有"}
      </div>
    </div>
  );
};

export default Footer;
