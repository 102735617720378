import "./App.css";
import Home from "./pages/Home";
import Header from "./components/Header/Header.js";
import Products from "./pages/Products";
import Shops from "./pages/Shops";
import About from "./pages/About";
import Error from "./pages/Error";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Header lang="english" />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/shops",
        element: <Shops />,
      },
      {
        path: "/aboutus",
        element: <About />,
      },
      {
        path: "*",
        element: <Error />,
      },
    ],
  },
  {
    path: "/zh/",
    element: <Header lang="chinese" />,
    children: [
      { path: "/zh/", element: <Home /> },
      {
        path: "/zh/products",
        element: <Products />,
      },
      {
        path: "/zh/shops",
        element: <Shops />,
      },
      {
        path: "/zh/aboutus",
        element: <About />,
      },
      {
        path: "/zh/*",
        element: <Error />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
