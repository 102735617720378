import newsdata from "../../data/news.json";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./News.css";
import Article from "../Article/Article.js";
import ArticleDetail from "../ArticleDetail/ArticleDetail";

const News = () => {
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [showArticle, setShowArticle] = useState(false);
  const isLastNews = currentNewsIndex === newsdata.length - 1;
  const isFirstNews = currentNewsIndex === 0;

  const [language] = useOutletContext();
  const isChinese = language === "chinese";

  const nextNews = () => {
    if (!isLastNews) {
      setCurrentNewsIndex(currentNewsIndex + 1);
    }
  };

  const previousNews = () => {
    if (!isFirstNews) {
      setCurrentNewsIndex(currentNewsIndex - 1);
    }
  };

  const moveSlideHandler = (index) => {
    index = parseInt(index);
    setCurrentNewsIndex(index);
  };

  const articleModalHandler = () => {
    setShowArticle(!showArticle);
  };

  useEffect(() => {
    console.log(language);
  }, [language]);

  return (
    <div className="news">
      {showArticle && (
        <ArticleDetail
          article={newsdata.slice(0).reverse()[currentNewsIndex]}
          onExit={articleModalHandler}
        />
      )}
      <h1>{isChinese ? "最新消息" : "Latest News"}</h1>
      <div className="newsholder">
        {!isFirstNews && (
          <div className="left-arrow" onClick={previousNews}>
            〈
          </div>
        )}
        <div className="news-slider">
          {newsdata
            .slice(0)
            .reverse()
            .map((article, index) => {
              return (
                <div
                  className={
                    index === currentNewsIndex ? "slide active" : "slide"
                  }
                  key={index}
                >
                  {index === currentNewsIndex && (
                    <Article
                      article={article}
                      onClickHandler={articleModalHandler}
                    />
                  )}
                </div>
              );
            })}
          <div className="slider-dots">
            {[...Array(newsdata.length).keys()].map((index) => {
              return (
                <div
                  className={index === currentNewsIndex ? "dot-active" : "dot"}
                  key={index}
                  onClick={() => {
                    moveSlideHandler(index);
                  }}
                ></div>
              );
            })}
          </div>
        </div>

        {!isLastNews && (
          <div className="right-arrow" onClick={nextNews}>
            〉
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
