import "./Holder.css";

const Holder = (props) => {
  return (
    <div className="holder">
      <div className="holder-child">{props.children}</div>
    </div>
  );
};

export default Holder;
