import "./Cover.css";
import { useOutletContext } from "react-router-dom";

const Cover = (props) => {
  const [language, ageConfirm] = useOutletContext();
  const isChinese = language === "chinese";
  const cover = props.cover;
  return (
    <div className="container" style={{ backgroundImage: `url(${cover})` }}>
      {ageConfirm && (
        <div className="container-bg">
          {isChinese && (
            <div className="cover">
              <div className="page_name">{props.pageNameChinese}</div>
              {props.establish && (
                <div className="establish">
                  <div className="line"></div>
                  <p>成立於1997年</p>
                  <div className="line"></div>
                </div>
              )}
              <div className="description">{props.descriptionChinese}</div>
            </div>
          )}
          {!isChinese && (
            <div className="cover">
              <div className="page_name">{props.pageNameEnglish}</div>
              {props.establish && (
                <div className="establish">
                  <div className="line"></div>
                  <p>Established in 1997</p>
                  <div className="line"></div>
                </div>
              )}
              <div className="description">{props.descriptionEnglish}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cover;
