import Holder from "../components/Holder/Holder";
import Cover from "../components/Cover/Cover";
import News from "../components/News/News";
import Footer from "../components/Footer/Footer";
import cover from "../assets/home-cover.jpeg";
import "./Home.css";

const Home = () => {
  return (
    <div className="home-body">
      <Cover
        pageNameChinese="新信威煙業貿易有限公司"
        descriptionChinese="古巴雪茄澳門總代理"
        pageNameEnglish="San Son Wai Cigars Trading Company Limited"
        descriptionEnglish="Distributor of Cuban Cigars in Macau"
        cover={cover}
        establish={true}
      />
      <Holder>
        <News />
      </Holder>
      <Footer />
    </div>
  );
};

export default Home;
