import Cover from "../components/Cover/Cover";
import wine from "../assets/wine.jpeg";
import azan from "../assets/azan-background.jpeg";
import "./Products.css";
import ca from "../assets/ca-background.png";
import caLogo from "../assets/ca-logo.png";
import caRr from "../assets/ca-reserva.png";
import caGr from "../assets/ca-gr.png";
import cigar from "../assets/cigar-background.png";
import coh from "../assets/coh-background.jpeg";
import dv from "../assets/dv-background.jpeg";
import "./Products.css";
import Footer from "../components/Footer/Footer";
import noncuban from "../assets/non-cuban.jpeg";
import { useState, useRef, useEffect } from "react";
import ProductModal from "../components/ProductModal/ProductModal";
import BrandDetail from "../components/BrandDetail/BrandDetail";
import { useOutletContext } from "react-router-dom";
import jumbotron from "../assets/walk-in-humidor.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import productdata from "../data/brands.json";

const Products = () => {
  const [language] = useOutletContext();
  const isChinese = language === "chinese";

  // const cuban = [
  //   {
  //     id: 1,
  //     brand: "Bolivar",
  //     img: require("../assets/brands/bolivar.png"),
  //     descriptionEng:
  //       "The great historic figure of the 19th Century, Simon Bolivar was a hero who liberated much of South America from Spanish rule. In 1902, the cigar brand Bolivar was created with full bodied flavor to incorporate with this powerful figure to match his might.",
  //     descriptionChinese:
  //       "Simon Bolivar 在19世紀中釋放多個被西班牙統治的南美國家，是當代其中一位偉大的歷史人物。 在1902年，Bolivar 這個雪茄品牌正式成立。為配合這位有具有權威的偉大人物，這品牌的雪茄的強度都較為濃厚。",
  //   },
  //   {
  //     id: 2,
  //     brand: "Cohiba",
  //     img: require("../assets/brands/cohiba.png"),
  //     descriptionEng:
  //       "The name Cohiba is an ancient Taino Indian word from the bunches of tobacco leaves that Columbus first saw being smoked by the original inhabitants of Cuba in 1492 - the earliest known form of a Habano. The original name should be Cuaba, but the word was misintrepreted as Cohiba due to discrepancy of the pronunciation.\nIt was created in 1966 for President Fidel Castro himself and was made at the then top secret, but now world famous, El Laguito factory. Not until 1982, Cohiba has been available in limited quantities to the open market. \nThe leaves for Cohiba are the 'selection of the selection' from the 5 finest tobacco fields.",
  //     descriptionChinese:
  //       "COHIBA 名字的由來，是來自哥倫布時代。當時哥倫布在1492年發現古巴時，看到當地的印地安人正在吸食一束一束的煙葉，他們稱之為 CUABA，但後來因為發音誤差慢慢練變為COHIBA。 \nCOHIBA 在1966 年為總統卡斯特羅創立，是古巴的旗艦品牌，以前這個品牌的雪茄只供應給國外作為外交禮物。自1982年起，COHIBA 開始開放在公眾市場出售，從此成為全球首屈一指的雪茄品牌。\n捲製COHIBA 雪茄過程裏，所有煙葉都是在五個最優質的地區精挑細選，從而捲製出頂級的雪茄。",
  //   },
  //   {
  //     id: 3,
  //     brand: "Cuaba",
  //     img: require("../assets/brands/Cuaba-1.png"),
  //     descriptionEng:
  //       "The distinctive shape known as double figuardo is the speical charactristic of every Cuaba cigar. At the start Cuaba offered only four comparatively small sizes. Since 2003, three much larger sizes Distinguido, Salomon, and Diadema have been released. Similar to Cohiba, the name of Cuaba comes from a Taino Indian word dating back to Colubmbus.",
  //     descriptionChinese:
  //       "Cuaba 品牌的所有雪茄都是以兩頭尖的形狀聞名於19世紀。品牌創立初期，Cuaba 只有4款尺寸較少的雪茄，自2003年開始加入三款尺寸較大的雪茄: Distinguido, Salomon 和 Diadema。Cuaba 的名字由來，像Cohiba 一樣，來自印地安人時代，當時的印地安人稱一種燃燒的灌木為Cuaba。",
  //   },
  //   {
  //     id: 4,
  //     brand: "Diplomaticos",
  //     img: require("../assets/brands/diplomaticos.png"),
  //     descriptionEng:
  //       "Diplomaticos is a comparatively recent brand dating from the 1960's. It bears a marked resemblance to Montecristo, using numbers not names to describe its sizes. \n\n All sizes are Tripa Larga, Totalmente a Mano - long filler, totally hand made - using a blend of filler and binder tobaccos from the Vuelta Abajo Zone.",
  //     descriptionChinese:
  //       "Diplomaticos創立於1960年代，是一款相對近期的品牌。型號命名方式與Montecristod相近，都是以號碼而非名字去命名雪茄型號。\n\n 品牌的所有型號都是全手捲長茄芯，使用Vuelta Abaja地區的煙葉作為茄芯和茄套捲製而成的。",
  //   },
  //   {
  //     id: 5,
  //     brand: "El Rey del Mundo",
  //     img: require("../assets/brands/es_rey_del_mundo.png"),
  //     descriptionEng:
  //       'The meaning of El Rey del Mundo  in English is "The King of the world" which was founded in 1882 in Antonio Allones cigar factory as a high quality cigar brand. The success of the brand which is renowned for its light to medium flavour and consistency still continues nowadays.',
  //     descriptionChinese:
  //       "El Rey del Mundo 的釋名為 世界之王，是Antonio Allones 雪茄廠在1882年創立的優質雪茄品牌。此品牌的成功仍存在至今，其雪茄濃度偏向淡到中等。",
  //   },
  //   {
  //     id: 6,
  //     brand: "Fonseca",
  //     img: require("../assets/brands/fonseca.png"),
  //     descriptionEng:
  //       "Fonseca was founded in the 1890's by Don Franciso Fonseca, a handsome gentleman who cared a lot for appearances. Even in his cigars, he dressed silky tissue paper so as to keep good appearance. This tradition still continues to this day.",
  //     descriptionChinese:
  //       "FONSECA 是Don Francisco Fonseca 在1890年代創立的。 創立人對外貎非常講究，他亦在雪茄包裝上有很高的要求。這品牌的雪茄每一支都用薄薄的透氣紙包裝著，這傳統至今仍保留著。",
  //   },
  //   {
  //     id: 7,
  //     brand: "H.Upmann",
  //     img: require("../assets/brands/h_upmann.png"),
  //     descriptionEng:
  //       "A banker from German, Herman Upmann founded H.Upmann in 1844. He was a cigar lover who moved to Havana and set up as both banker and cigar maker due to his passion to cigars. Although his bank closed in 1920s, the brand he founded still lives on as an elegant cigar with light and medium flavour.",
  //     descriptionChinese:
  //       "H.Upmann 是由一位德國銀行家 Herman Upmann 在1844年創立。當時因為他非常熱愛古巴雪茄，所以特意移居到古巴設立銀行及雪茄廠。雖然他的銀行在1920年代結業，但他所創立的雪茄品牌仍然憑著其中等濃度的雪茄而聞名至今。",
  //   },
  //   {
  //     id: 8,
  //     brand: "Hoyo de Monterrey",
  //     img: require("../assets/brands/hoyo_de_monterrey.png"),
  //     descriptionEng:
  //       "Hoyo de Monterrey was founded in 1865. The light flavour of the blend makes it attractive choice for those who seek a delicate and aromatic cigars. Double Corona, Epicure No.1, Epicure No.2 are the most popular sizes of the brand.\n Hoyo de Monterrey was the first brand to introduce the size of Petit Robusto. Although this size is short, the 50 ring gauge offers a good way to enjoy a cigar with thicker format while always have enough time to enjoy.",
  //     descriptionChinese:
  //       "Hoyo de Monterrey 的品牌在1865年創立。這個品牌的味道比較細緻芳香，適合追求味道清淡優雅的雪茄客。Double Corona 和 Epicure 系列 的 No.1 和 No.2是這個品牌最有名氣的尺寸。\n\nHoyo de Monterrey 是第一個品牌在2005年推出 Petit Robusto 尺寸。這個尺寸的雪茄雖然比較短但環徑比較粗，適合喜歡抽粗雪茄而又沒有太多空閒時間的雪茄客。",
  //   },
  //   {
  //     id: 9,
  //     brand: "Jose L. Piedra",
  //     img: require("../assets/brands/JoseLpiedra-1.png"),
  //     descriptionEng:
  //       "Jose L. Piedra was founded by Piedra family who moved from Spain to Cuba in 1880s. All Jose L. Piedra cigars are totally hand-made with short filler tobacco - a technique with a blend that provides a medium to full flavour.",
  //     descriptionChinese:
  //       "Jose L. Piedra 是由來自於西班牙的 Piedra 家族創立，這個品牌的雪茄是以短茄芯全手工捲製的，採用這個技術的雪茄帶出中等到濃厚的味道。",
  //   },
  //   {
  //     id: 10,
  //     brand: "Juan Lopez",
  //     img: require("../assets/brands/juan_lopez.png"),
  //     descriptionEng:
  //       "In 1870s, a Spanish businessman called Juan Lopez Diaz founded a cigar brand in Cuba named after himself. Although this brand is not quite popular yet, Juan Lopez has always attracted numerous cigar aficionados particularly for the Seleccion No.1 and Seleccion No.2.",
  //     descriptionChinese:
  //       "在1870年代，一位西班牙商人 Juan Lopez Diaz 以自己的名字創立了這個品牌。雖然這個品牌的雪茄不是很有名氣，但是其雪茄 Seleccion No.1 及 Seleccion No.2 卻經常吸引很多雪茄愛好者。",
  //   },
  //   {
  //     id: 11,
  //     brand: "La Flor de Cano",
  //     img: require("../assets/brands/la-flor-cano.png"),
  //     descriptionEng:
  //       "Totally hand made with short filler, La Flor de Cano cigars, which sell at a medium price level, offer a good balance between flavour and aroma.",
  //     descriptionChinese:
  //       "La Flor de Cano 這款中等價格的品牌有著平衡的味道及芳香。現今這款品牌的雪茄是以全手工及短茄芯捲製而成的。",
  //   },
  //   {
  //     id: 12,
  //     brand: "La Gloria Cubana",
  //     img: require("../assets/brands/lgc.png"),
  //     descriptionEng:
  //       "La Gloria Cubana is a little know jewel amongst Habano brands. Nevertheless it enjoys a long history. \nFounded in 1885, its name alone clearly defines its origin. For over a century it has attracted a small but loyal following amongst the most knowledgeable smokers. \n The brand is characterized by its elegant presentation, especially in the Medaille d'Or Series.",
  //     descriptionChinese:
  //       "La Gloria Cubana 可以說是滄海遺珠的一個雪茄品牌，即使如此，它亦是有着悠久歷史的品牌。\n\n品牌創辦於1885年，其品牌名字有着「古巴榮耀」的意思，非常清晰地說明了雪茄的產地。超過一世紀的時光，這個品牌深深吸引了一小眾忠誠雪茄愛好者。\n\nLa Gloria Cubana以優雅的風味為特點，於其Medaille d'Or 系列更能特顯出其獨特風味。",
  //   },
  //   {
  //     id: 13,
  //     brand: "Montecristo",
  //     img: require("../assets/brands/montecristo.png"),
  //     descriptionEng:
  //       'Montecristo is considered to be the best known cigars which are appreciated by most of cigar aficionados around the world. It is often viewed as the benchmark for many Habanos smokers against which other brands are judged. \nMontecristo was named after the hero of Alexandre Duma\'s famous novel "The Count of Montecristo" which was a firm favorite when read by the Lector to the cigar rollers at the factory where it was founded in 1935.',
  //     descriptionChinese:
  //       "Montecristo 是國際上最有名的雪茄品牌，成為雪茄愛好者對其他雪茄品牌評分的標準。\n\nMontecristo 的名字取自於一本著名的小說 <<基督山伯爵>> (The Count of Montecristo)。在捲煙廠中有安排專員在台上為捲煙工人講述新聞及故事，而這本小說深受工人們喜愛，最後在1935年這個品牌正式成立。",
  //   },

  //   {
  //     id: 14,
  //     brand: "Partagas",
  //     img: require("../assets/brands/partagas.png"),
  //     descriptionEng:
  //       "A famous cigar brand in Cuba, Partagas was founded in 1845 in the Capital city of Cuba - Havana. Partagas was quickly recognized by its deep, earthy flavour.\nThe most popular size of Partagas is Serie D. No.4 which is a classic size of Robusto. In 2005, Partagas introduced Serie P No.2, a piramide size, which became popular in a short period of time.",
  //     descriptionChinese:
  //       "Partagas 是古巴雪茄中最有名的品牌之一，於1845年在古巴首都哈瓦那創立。這品牌的雪茄憑著其濃厚的味道，在創立不久後已經被受追捧。\n\nPartagas 最受歡迎的雪茄是 Serie D. No.4，這款雪茄是經典的羅布圖(Robusto) 尺寸。在2005年，Partagas Serie P No.2 的推出亦很快獲得雪茄客的青睞。",
  //   },
  //   {
  //     id: 15,
  //     brand: "Por Larranaga",
  //     img: require("../assets/brands/por_larranaga.png"),
  //     descriptionEng:
  //       "Por Larranaga was founded in 1834. During its long history, the brand has earned reputation for its consistency and good presentation.",
  //     descriptionChinese:
  //       "Por Larranaga 於1834年創立，是歷史悠久的雪茄品牌，其良好及一致的包裝外表享負盛名。",
  //   },
  //   {
  //     id: 16,
  //     brand: "Punch",
  //     img: require("../assets/brands/punch.png"),
  //     descriptionEng:
  //       "Punch is one of the oldest Cuban cigar brands. The brand was named after the clown Mr. Punch who represented a British Magazine in the mid 19th century. In 1931, the Punch merged with Hoyo de Monterrey and became La Corona factory.",
  //     descriptionChinese:
  //       "Punch 是古巴雪茄最古老的品牌之一，當時的創辨人看準英國發展迅速的雪茄市場，於19世紀中創立了這個品牌。在1931年， Punch 與Hoyo de Monterrey 的雪茄廠合拼，成為了現在的La Corona 雪茄廠。",
  //   },
  //   {
  //     id: 17,
  //     brand: "Quai D'Orsay",
  //     img: require("../assets/brands/qual_d_orsay.png"),
  //     descriptionEng:
  //       "Named after a street in Paris, Quai D'Orsay was founded in 1970s.  Its cigars are rolled with blends that confer light flavour.\nAll Quai d'Orsay sizes are made with a light tasting blend of filler and binder leaves from the Vuelta Abajo zone and its wrappers are mainly distinguished by their Claro (light brown) and Colorado Claro (mid brown) colours.",
  //     descriptionChinese:
  //       "Quai D'Orsay 在1970年代創立，命名於法國巴黎一條著名的街道。其雪茄都是由味道較淡的煙葉混合捲製的。",
  //   },
  //   {
  //     id: 18,
  //     brand: "Rafael Gonzalez",
  //     img: require("../assets/brands/rafael-gonzalez.png"),
  //     descriptionEng:
  //       "Rafael Gonzalez was created in the 1930's. It was in this brand that the 165mm by 42 gauge cigar, which has the factory name of Cervantes, was first called a Lonsdale after Lord Lonsdale, the famous English sporting Earl and cigar aficionado of that period. Now many other brands use the name as well.\n\n On its lid label each box carries a curious inscription written in English to the effect that the cigars should be smoked within one month of the date of shipment from Havana or otherwise they should be carefully matured for about one year.",
  //     descriptionChinese:
  //       "Rafael Gonzalez 品牌創立於1930年代，是第一款品牌以 42 x 165 廠家尺寸Cervantes的雪茄名命為Lonsdale，而Lonsdale的名字則是取自於英國最偉大的戶外運動紳士及雪茄愛好者 'Lord of Lonsdale'。至今已經有很多品牌使用Lonsdale這個名字名命雪茄型號。\n\n在每盒雪茄外盒的盒頂都寫有一段英文文稿，當中意思是此盒雪茄建議在從古巴哈瓦那運送日期後的一個月內享用，否則需要陳年存放大約一年。",
  //   },
  //   {
  //     id: 19,
  //     brand: "Ramon Allones",
  //     img: require("../assets/brands/ramon_allones.png"),
  //     descriptionEng:
  //       "Romon Allones is one of the big names in the history of Cuban cigars. It was founded in 1837 and is one of the oldest Cuban cigar brands still in production.\n A Galician by birth Ramon Allones was the first cigar manufacturer to pack his Habanos in boxes decorated with labels and stamped wit his brand's emblem. He was the pioneer of the packaging tradition that we know today.",
  //     descriptionChinese:
  //       "Ramon Allones 是古巴雪茄歷史中的大名之一，在1837年成立，是當今最古老的雪茄品牌之一。",
  //   },
  //   {
  //     id: 20,
  //     brand: "Romeo y Julieta",
  //     img: require("../assets/brands/romeo-julieta.png"),
  //     descriptionEng:
  //       "Romeo y Julieta was founded in 1875. The brand was named after William Shakespeare's tragic lovers.\nThe brand was recognized internationally during the early 20th Century due to its famous medium flavour cigars. Winston Churchill was one of the brand's fans. After his visit to Havana in 1946, Romeo y Julieta introduced a size called Churchill to commemorate his visit.",
  //     descriptionChinese:
  //       "Romeo y Julieta 品牌在1875創立，名字取至於莎士比亞故事的悲劇情侶－羅密歐與茱麗葉。\n\n這款雪茄在二十世紀初在國際市場上成名，英國首相溫斯頓．邱吉爾(Winston Churchill) 也是Romeo y Julieta 的忠實愛好者。在1946年邱吉爾拜訪古巴首都夏灣拿後，Romeo y Julieta 的品牌特意創立一款名為邱吉爾 (Churchill)的雪茄紀念他的到訪。\n\nRomeo y Julieta 因口味平衡及其芳香而廣受歡迎。",
  //   },
  //   {
  //     id: 21,
  //     brand: "San Cristobal de la Habana",
  //     img: require("../assets/brands/san-cristobal-habana.png"),
  //     descriptionEng:
  //       "San Cristobal de la Habana was the former name of Cuba's capital city, Havana. It was founded in 1999 to celebrate the new Millennium as a tribute to the city's long history.",
  //     descriptionChinese:
  //       "San Cristobal de la Habana 是古巴首都夏灣拿的原名，為紀念下一個千僖年，這個品牌在1999年創立。",
  //   },
  //   {
  //     id: 22,
  //     brand: "Sancho Panza",
  //     img: require("../assets/brands/sancho_panza.png"),
  //     descriptionEng:
  //       "Similar to Montecristo and Romeo y Julieta, Sancho Panza was named after a squire in a renowned novel. The brand was founded in 1848. Since then, the brand has gained its popularity due to the cigar's high quality.",
  //     descriptionChinese:
  //       "就像 Montecristo 和 Romeo y Julieta 一樣，Sancho Panza 的名字取自於世界著名的文藝作文，當中Sancho Panza 是故事裏的一位騎士的護衛。\n\n這個品牌創立於1848年，自此開始以其高質素的雪茄聞名於雪茄愛好者。",
  //   },
  //   {
  //     id: 23,
  //     brand: "Saint Luis Rey",
  //     img: require("../assets/brands/sant_luis_rey.png"),
  //     descriptionEng:
  //       "Saint Luis Rey was founded just before the Second World War. The brand was named after the tobacco filed which was famous for growing high quality wrapper. Its cigars are famous for the full flavour.",
  //     descriptionChinese:
  //       "Saint Luis Rey 剛好創立在第二次世界大戰前，此名字是取至於古巴著名生產優質茄衣的田地 San Luis。這品牌的雪茄味道較濃，因而受喜愛濃雪茄的人愛戴。",
  //   },
  //   {
  //     id: 24,
  //     brand: "Trinidad",
  //     img: require("../assets/brands/trinidad.png"),
  //     descriptionEng:
  //       "Trinidad was named after a World Heritage City - The Holy Trinidad which is located on the south coast of Cuba. Similar to Cohiba, Trinidad only offered to countries as diplomatical gifts. The cigars were available to the public only after 1998.",
  //     descriptionChinese:
  //       "Trinidad 命名於一個美麗的城市 - The Holy Trinidad，這個城市位於古巴南部海岸，現已被列入為世界文化遺產。就像Cohiba，以前的Trinidad 雪茄只供應給國外作為外交禮物，直至1998年才正式公開給大眾市場。",
  //   },
  //   {
  //     id: 25,
  //     brand: "Vegas Robaina",
  //     img: require("../assets/brands/vegas-robaina.png"),
  //     descriptionEng:
  //       "Robaina family has made a lot of contribution to tobacco plantation in Cuba since 1845. In order to recognize the farmers and the family, Vegas Robaina was introduced in 1997 with the picture of Don Alejandro Robaina who was famous for producing the finest wrapper leaves in his days.",
  //     descriptionChinese:
  //       "自1845年起 Robaina 家族就已經在種植雪茄煙草，在1997年古巴創立此品牌紀念品牌上的那位農夫 Don Alejandro Robaina 其對煙草種植知識及種植優質茄衣的貢獻。",
  //   },
  //   {
  //     id: 26,
  //     brand: "Vegueros",
  //     img: require("../assets/brands/vegueros.png"),
  //     descriptionEng:
  //       "Veguero has a meaning of farmer in Spanish. In order to recognized the countless generations that have cultivated tobacco in Cuba, Vegueros was introduced in 1966.",
  //     descriptionChinese:
  //       "Vegueros 在西班牙語是農夫的意思，為感謝無數的農夫為古巴雪茄的無私奉獻，Vegueros 這個品牌在1996年創立。",
  //   },
  // ];

  // const nica = [
  //   {
  //     id: 27,
  //     brand: "Azan",
  //     img: require("../assets/brands/Brand_Cigar_azan1.png"),
  //     descriptionEng:
  //       "Azan - The only cigar brand founded by Chinese in Cuba \n \nIn 1870s, Guan Ben Shan and his two brothers moved to Cuba and changed their names to Domingo Azan, Andres Azan, and Luis Azan respectively. In 1928, they settled down in Cuba and made their fortune by rolling cigars. Their cigars were manufactured using long filler tobacco grown at Hoyo de Manicaragua, which is rated as one of the high quality tobacco field in Cuba. God of Fortune blessed Domingo Azan in 1938 when he won a large amount of money from a lottery. However, he did not choose to retire. Instead, he invested the money into the expansion of the cigar  factory which offered a lot of job opportunities in the country. \n\nAfter the revolution in 1959, the cigar factory of Azan was nationalized and became a part of Empresa de Tabaco Torcido Villa Clara, Manicaragua, which specalized in rolling high quality cigars for export. Now, Azan is relocated to Nicaragua where they grow and manufacture their own cigars with under supervision by professionals from Cuba to ensure the high quality of the cigars.",
  //     descriptionChinese:
  //       "Azan - 世上唯一由中國人到古巴創辦的雪茄品牌\n\nAzan的品牌故事相當有趣，亦極富傳奇。 Azan創辦人名叫關本山，他在十九世紀40年代（即約1840年）在黃河流域一帶出生。在大約1870年，關本山和兩名兄弟移居到古巴，並取了一個古巴名字Domingo Azan，至於兩名兄弟則分別叫Andres and Luis。在1928 年，他們開始在古巴落地生根，並跟其他古巴人一樣，以捲製雪茄維生。他們製作的雪茄是以產自Hoyo de Manicaragua長菸葉作茄芯，當地被譽為古巴其中一個優質煙草產地，在當地非常有名。\n幸運之神降臨一直默默工作的關本山，在1938得到幸運之神的眷顧，竟然中了國家彩票的頭獎，得到巨額的彩金。雖然當時關本山已經相當年邁，但他沒有選擇退休，而是將獎金作投資，興建了當時設備最先進的製煙廠。不久，製煙廠不但成為當地的重要建設，也迅即遍布整個地區。 經過1959年發生的古巴革命之後，Azan製菸廠變成了國家經營的其中一部分，專門製作出口外地的高品質古巴雪茄。在和製煙廠僅一街之隔的城市博物館，則收藏了許多製煙廠的物品，包括雪茄、香煙包裝和廣告宣傳物品。\n現在Azan已於尼加拉瓜的埃斯特利重設精品製菸廠，並建立了戰略合作夥伴關係，指定由擁有多年菸葉處理及工作運作經驗的古巴專業人員領導，負責從數百箱的煙草中選出當中品質最高的，確保所採用的材料都是最上乘的。",
  //   },
  //   {
  //     id: 28,
  //     brand: "Neya",
  //     img: require("../assets/brands/Brand_CiGAR_NEYA_F8.png"),
  //     descriptionEng:
  //       "The Neya brand comes in five vitolas. The first three comes in Classic sizes of Petit Corona, Robusto, and Canonazo with a medium to strong flavor EPF(Evolutionary Progressive Flavour) experience. The bigger size symbol as they carry a F8 symobl as they are full-bodied flavors, truly strong product of a very well fermented Nicaraguan Ligero Leaf added to the blend. \n\n Neya is a premium brand which comes in a cedar 20-count cabinet. The filler and binder are both Nicaraguan while the wrappers is a Habano Ecuador.",
  //     descriptionChinese:
  //       "Neya品牌中有5款尺寸，當中的3款屬於經典尺寸的Petit Corona, Robusto 和Canonazo 屬於中等到濃郁風味並帶有漸進變化風味的體驗。另一款環徑較大的系列名為F8系列屬於濃郁口感，是一款口感真正濃厚的尼加拉瓜雪茄。\n\nNeya是一款高端的尼加拉瓜雪茄品牌，以20支木盒包裝，其茄芯和茄套均使用產自尼加拉瓜的煙葉，而茄衣則採用厄瓜多爾煙葉。",
  //   },
  //   {
  //     id: 29,
  //     brand: "Padrón",
  //     img: require("../assets/brands/padron.png"),
  //     descriptionEng:
  //       "With over fifty-six years to create a perfect cigar and more than 100 years to create a perfect legacy, the Padrón Family understands the significance of time. Padrón cigars deliver only the finest, hand crafted cigars with the flavor of the Cuban heritage out of which the Padrón recipe was born. Padrón's primary mission is the exceptional quality of our product, not the quantity produced. Their success has been recognized and reinforced by its cigar portfolios in which the majority gets 90+ high ratings.",
  //     descriptionChinese:
  //       "經歷了超過56年的經驗創造出完美的雪茄，並且擁有超過100年的歲月創造出完美的遺贈，Padrón家族充份了解時間的重要性。Padrón雪茄只傳遞出最優質的手工製雪茄，並且富有當初Padrón雪茄創立於古巴時期由古巴遺留下來的雪茄配方的古老風味。Padrón的理念是出眾的完美品質，而不是產量。品牌的成功可由其眾多高於90評分的雪茄中充分體現出其品質及雪茄愛好者的愛戴。",
  //   },
  //   {
  //     id: 30,
  //     brand: "Perdomo",
  //     img: require("../assets/brands/logo-perdomo.webp"),
  //     descriptionEng:
  //       " As a vertically integrated cigar manufacturer, Perdomo controls every process, from agricultural operations in the fertile valleys of the Estelí, Condega, and the famed Jalapa regions in Nicaragua to the distribution center in Miami, Florida \n\n Perdomo Cigars are characterized by the three attributes Tabacalera Perdomo values most: quality, tradition, and excellence. With an unparalleled attention to detail, and a commitment to integrity that's been handed down from generation to generation, when you buy Perdomo cigars you get more than the finest hand-rolled cigars in the world; you get the pleasure of smoking an ongoing legacy of distinction.",
  //     descriptionChinese:
  //       "Perdomo 擁有自家的煙田和捲煙廠，因此雪茄由種植於尼加拉瓜Esteli, Condega和Jalapa的肥沃農地生長，到雪茄捲制並運送到美國邁亞密分銷的所有過程都由自家團隊親自嚴格控制，務求保持雪茄的品質。 \n\n Perdomo 雪茄煙草田和雪茄廠有三大堅持：質量、傳統、和優越。經過對細節嚴格要求和對質量的持續監控，當客人購買到Perdomo雪茄時會發現其品質是世界級優質的雪茄。 ",
  //   },
  //   {
  //     id: 31,
  //     brand: "Roberto P. Duran",
  //     img: require("../assets/brands/Brand_Cigar_duran1.png"),
  //     descriptionEng:
  //       "The Roberto Duran Cigar combines the best quality tobacco fillers from Nicaragua and the Latin American region with the super-premium Habana Criollo Colorado wrapper, produced in their plantation in Ecuador. This high quality wrapper is superiority in terms of texture, sweetness and the famous Colorado color.\n\nThe blends has been carefully balanced to perfection by the team of tobacco experts at Nicatabaco factory in Esteli, resulting in a very enjoyable medium to strong and progressive flavor experience that you won't forget.",
  //     descriptionChinese:
  //       "Roberto Duran 雪茄組合了最高品質的尼加拉瓜及拉丁美洲的煙葉，並使用產自厄瓜多爾的Criollo Colorado超高端茄衣捲製而成的。這款高質量的茄衣在質感上、甘甜度、以及順滑的顏色上都是絕對出眾的。\n\n雪茄的煙葉調配經過細緻的平衡達致口感上的完美，這個成果都是由品牌於尼加拉瓜出色的煙草專家團隊多年的努力，為雪茄愛好者帶來這款中等到濃郁並帶有口感漸進變化的難忘體驗。",
  //   },
  //   {
  //     id: 32,
  //     brand: "Rocky Patel",
  //     img: require("../assets/brands/rp-logo.png"),
  //     descriptionEng:
  //       "American Cigar Company was rebranded as Rocky Patel in 2003. The founder Mr. Rocky Patel has been originally a super cigar fan who fell in love with cigars when he tried his first one in the 1990's. He demands high standards of his cigars, using wrappers, binders, and fillers from different regions, as well as spending much of effort in blending all these tobacco from different regions. His dedication has been successful in creating exquisite and complex cigars. Rocky Patel is also very strict in quality control, resulting in a high consistency in the brand's cigar production. Among all, one of the brand's series Sun Grown Maduro has been highly rated with 95 score in Cigar Aficionado.",
  //     descriptionChinese:
  //       "美國雪茄公司Rocky Patel(洛基·帕特爾)於2003 年改名後正式成立。創辦人洛基·帕特爾先生本身是一位超級雪茄迷，於九十年代初嚐過第一支雪茄後隨即愛上，他對雪茄的要求非常高，除了採用不同地區的茄衣丶茄芯及茄套，亦下了不少功夫在調配不同產地的菸葉，成功創造優質及富層次感的雪茄。洛基·帕特爾亦非常重視質量控制，其出品的雪茄以高度的一致性見稱, 其中的日曬馬杜羅系列更在Cigar Aficionado 雜誌上獲得95 分的高評分。​",
  //   },
  //   {
  //     id: 33,
  //     brand: "Fratello",
  //     img: require("../assets/brands/fratello-logo.png"),
  //     descriptionEng:
  //       "Omar de Frias is the founder and owner of Fratello Cigars, a premium cigar brand.  He had a successful career at NASA as an analyst managing a budget of 5 billion dollars. \n\nAfter leaving NASA, de Frias ventured into the cigar industry, driven by his passion for cigars and desire to create high-quality cigars. He founded Fratello Cigars in 2013, and the brand quickly gained recognition for its unique blends and branding. \n\nIn 2024, as NASA is going back to the moon, Fratello will be the first cigar brand that has its cigar ring going to the moon.",
  //     descriptionChinese:
  //       "Fratello雪茄品牌創立人Omar de Frias曾經是NASA美國太空總署的分析師，當時負責管理預算超過5億美元的項目。為了追逐自己對雪茄喜好，他於2013年創立高端雪茄品牌Fratello，並得到市場對其品質和品牌價值的廣泛的認可。隨着NASA美國太空總署計劃於2024年重返月球，Fratello雪茄品牌將會成為首個雪茄戒指登陸月球的雪茄品牌。​",
  //   },
  // ];

  // const dom = [
  //   {
  //     id: 34,
  //     brand: "Davidoff",
  //     img: require("../assets/brands/dv-logo.png"),
  //     descriptionEng:
  //       "Davidoff is one of the most luxurious cigar brands across the world. The brand was founded by Zino Davidoff and cigars were manufactured in Cuba until 1990. Nowadays, their production sites are located in Dominican Republic and Honduras, whereas the tobaccos come from a wide range of countries, including Dominican Republic, Honduras, Nicaragua, Ecuador, Brazil, Mexico, Peru etc.\n\nDavidoff continues to deliver outstanding quality and craftsmanship, with a high level of innovation which keeps surprising and delighting cigar aficionados around the world.",
  //     descriptionChinese:
  //       "Davidoff是世界上最奢華的雪茄品牌之一，品牌由Zino Davidoff創立並於古巴生產直至到1990年後撤出古巴。直至今天，品牌的生產主要於多明尼加共和國和洪都拉斯，而煙葉則採用產自多個不同國家的煙草，當中包括多明尼加共和國、洪都拉斯、尼加拉瓜、厄瓜多爾、巴西、墨西哥、秘魯等國家。\n\nDavidoff 不斷為雪茄愛好者帶來出眾的品質及雪茄工藝，並抱着不斷的創新為全球的雪茄愛好者帶來驚喜。",
  //   },
  //   {
  //     id: 35,
  //     brand: "Zino Nicaragua",
  //     img: require("../assets/brands/zino-logo.jpg"),
  //     descriptionEng:
  //       "Zino Nicaragua is characterized with medium intensity which is well-suited for beginners and occasional cigar smokers. The elegant aromas of blend include cedar wood, coffee, and fresh spices. Cigars are long-filler handmade with premium tobacco with an exceptional value for money.",
  //     descriptionChinese:
  //       "Zino Nicaragua 的特色為中等濃度，適合初品嘗雪茄或不經常享用雪茄的人仕。其優雅的雪茄調配風味當中帶有香柏木、咖啡以及香料。這個品牌的雪茄都是以高端煙葉全手工捲製的長茄芯雪茄，是一款性價比極高的雪茄品牌。",
  //   },
  //   {
  //     id: 36,
  //     brand: "La Galera",
  //     img: require("../assets/brands/la-galera.png"),
  //     descriptionEng:
  //       "World-renowned tobacco grower and cigar master blender José 'Jochy' Blanco has spent decades as one of the most respected names in the industry, and in 2016 introduced his latest creation: La Galera. Based out of Tabacalera Palma in the Dominican Republic, La Galera offers premium cigars made with the expertise that only one of the most experienced and prolific cigar makers of all time can produce. Rich, hearty blends and flawless craftsmanship are the order of the day, and deliver in spades each and every time.",
  //     descriptionChinese:
  //       "世界盛名的煙葉種植專家和雪茄調配大師 José 'Jochy' Blanco 經過幾個年代的成就成為雪茄行業裏其中一位最受尊敬的名字，而於2016年他正式推出自己的品牌 La Galera(拉.加萊拉)。使用位於多明尼加共和國內Tabacalera Palma 內自家種植的煙葉和自家營運的捲煙廠，拉.加萊拉品牌生產的優質雪茄都是根據於多年類積的專業的技術，該技術是由雪茄行業內最受尊敬的大師多年來累積的豐富知識與專業經驗，為廣大雪茄愛好者用心製作出完美無瑕且香濃的雪茄功藝產品。",
  //   },
  //   {
  //     id: 37,
  //     barnd: "La Instructora",
  //     img: require("../assets/brands/la-instructora.jpeg"),
  //     descriptionEng:
  //       "The delicate and dedicated selection of each of the tobaccos used make La Instructora rare, unique and excellent cigars. Only fine selection of five-year-old rare tobaccos aged in the facilities of Tabacalera Palma is used. Unique and incomparable blends created by master blender Jochy Blanco with years of trial and error until reaching perfection. Fine boxes of precious wood made by hand by artists from the Cibao Valley.",
  //     descriptionChinese:
  //       "採用經細緻及精心挑選的煙葉賦予La Instructora品牌成為稀有、獨特及優質的雪茄。La Instructora僅選用於Tabacalera Palma雪茄廠內陳年5年的稀有煙葉，經由調配大師Jose Jochy Blanco經由多年來不斷嘗試最後調配出獨特而無與倫比的配方並達至完美的品質。雪茄包裝使用全手工製作的木盒並採用多明尼加錫瓦奧谷地(Cibao Valley)的藝術家的畫作結合出精緻的包裝。",
  //   },
  //   {
  //     id: 38,
  //     brand: "Fratello",
  //     img: require("../assets/brands/fratello-logo.png"),
  //     descriptionEng:
  //       "Omar de Frias is the founder and owner of Fratello Cigars, a premium cigar brand.  He had a successful career at NASA as an analyst managing a budget of 5 billion dollars. \n\nAfter leaving NASA, de Frias ventured into the cigar industry, driven by his passion for cigars and desire to create high-quality cigars. He founded Fratello Cigars in 2013, and the brand quickly gained recognition for its unique blends and branding. \n\nIn 2024, as NASA is going back to the moon, Fratello will be the first cigar brand that has its cigar ring going to the moon.",
  //     descriptionChinese:
  //       "Fratello雪茄品牌創立人Omar de Frias曾經是NASA美國太空總署的分析師，當時負責管理預算超過5億美元的項目。為了追逐自己對雪茄喜好，他於2013年創立高端雪茄品牌Fratello，並得到市場對其品質和品牌價值的廣泛的認可。隨着NASA美國太空總署計劃於2024年重返月球，Fratello雪茄品牌將會成為首個雪茄戒指登陸月球的雪茄品牌。​",
  //   },
  // ];

  // const hondura = [
  //   {
  //     id: 39,
  //     brand: "Camacho",
  //     img: require("../assets/brands/camacho-logo.png"),
  //     descriptionEng:
  //       "Camacho Cigars are the torchbearer of the legendary original Corojo leaf. A one-of-a-kind tobacco that began in Cuba and lives on in Camacho Cigars.  Perfecting and preserving the authentic Corojo seed is the foundation of their cigar blends.",
  //     descriptionChinese:
  //       "Camacho 是傳奇煙葉Corojo的領頭品牌，Corojo是一款始於古巴的獨特煙葉，並於Camacho雪茄中延續。完善並保留這款真正的古巴Corojo種子是Camacho雪茄品牌的基石。",
  //   },
  //   {
  //     id: 40,
  //     brand: "Rocky Patel",
  //     img: require("../assets/brands/rp-logo.png"),
  //     descriptionEng:
  //       "American Cigar Company was rebranded as Rocky Patel in 2003. The founder Mr. Rocky Patel has been originally a super cigar fan who fell in love with cigars when he tried his first one in the 1990's. He demands high standards of his cigars, using wrappers, binders, and fillers from different regions, as well as spending much of effort in blending all these tobacco from different regions. His dedication has been successful in creating exquisite and complex cigars. Rocky Patel is also very strict in quality control, resulting in a high consistency in the brand's cigar production. Among all, one of the brand's series Sun Grown Maduro has been highly rated with 95 score in Cigar Aficionado.",
  //     descriptionChinese:
  //       "美國雪茄公司Rocky Patel(洛基·帕特爾)於2003 年改名後正式成立。創辦人洛基·帕特爾先生本身是一位超級雪茄迷，於九十年代初嚐過第一支雪茄後隨即愛上，他對雪茄的要求非常高，除了採用不同地區的茄衣丶茄芯及茄套，亦下了不少功夫在調配不同產地的菸葉，成功創造優質及富層次感的雪茄。洛基·帕特爾亦非常重視質量控制，其出品的雪茄以高度的一致性見稱, 其中的日曬馬杜羅系列更在Cigar Aficionado 雜誌上獲得95 分的高評分。​",
  //   },
  // ];
  const [showDescription, setShowDescription] = useState(false);
  const [selBrand, setSelBrand] = useState();

  const showBrand = (brand) => {
    setShowDescription(true);
  };

  const exitModalHandler = () => {
    setShowDescription(false);
  };

  const refCigar = useRef(null);
  const refNoncuban = useRef(null);
  const refWine = useRef(null);

  const handleCigarClick = () => {
    refCigar.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleNoncubanClick = () => {
    refNoncuban.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleWineClick = () => {
    refWine.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    AOS.init({ duration: 1300, once: false });
    AOS.refresh();
  }, []);

  return (
    <>
      {showDescription && (
        <ProductModal onExit={exitModalHandler}>
          <BrandDetail product={selBrand} />
        </ProductModal>
      )}
      <div className="productBody">
        <Cover
          pageNameChinese="來自世界各地的優質產品"
          pageNameEnglish="Premium Products for Cigar Aficionados"
          cover={jumbotron}
        />
        <div className="products">
          <div
            className="productType"
            style={{ backgroundImage: `url(${cigar})` }}
            onClick={handleCigarClick}
          >
            <div className="type" data-aos="zoom-out">
              {isChinese ? <h5>古巴雪茄</h5> : <h5>Habanos</h5>}
            </div>
            <div className="learnMore" data-aos="zoom-out">
              {isChinese ? <p>了解更多</p> : <p>Learn More</p>}
            </div>
          </div>
          <div
            className="productType"
            style={{ backgroundImage: `url(${noncuban})` }}
            onClick={handleNoncubanClick}
          >
            <div className="type" data-aos="zoom-out">
              {isChinese ? <h5>世界雪茄</h5> : <h5>World Cigars</h5>}
            </div>
            <div className="learnMore" data-aos="zoom-out">
              {isChinese ? <p>了解更多</p> : <p>Learn More</p>}
            </div>
          </div>
          <div
            className="productType"
            style={{ backgroundImage: `url(${wine})` }}
            onClick={handleWineClick}
          >
            <div className="type" data-aos="zoom-out">
              {isChinese ? <h5>酒類產品</h5> : <h5>Wine</h5>}
            </div>
            <div className="learnMore" data-aos="zoom-out">
              {isChinese ? <p>了解更多</p> : <p>Learn More</p>}
            </div>
          </div>
        </div>
        <div
          className="background"
          style={{ backgroundImage: `url(${coh})` }}
          ref={refCigar}
        ></div>

        <div className="origin">
          <h3 className="country">
            {isChinese ? "古巴雪茄品牌" : "Cuban Cigars"}
          </h3>
          <div className="brands" data-aos="zoom-in">
            {productdata.map(
              ({ brand, img, origin, descriptionEng, descriptionChinese }) => {
                return (
                  origin.includes("cuba") && (
                    <div
                      className="brand"
                      onClick={() => {
                        showBrand(brand);
                        setSelBrand({
                          brand,
                          img,
                          descriptionEng,
                          descriptionChinese,
                        });
                      }}
                    >
                      <img className="brandImage" src={img} alt="brand-logo" />
                    </div>
                  )
                );
              }
            )}
          </div>
        </div>
        <div
          className="background"
          style={{ backgroundImage: `url(${azan})` }}
          ref={refNoncuban}
        ></div>
        <div className="origin" style={{ backgroundColor: "grey" }}>
          <h3 className="country">
            {isChinese ? "尼加拉瓜雪茄品牌" : "Nicaraguan Cigars"}
          </h3>
          <div className="brands" data-aos="zoom-in">
            {productdata.map(
              ({ brand, img, origin, descriptionEng, descriptionChinese }) => {
                return (
                  origin.includes("nicaragua") && (
                    <div
                      className="brand"
                      onClick={() => {
                        showBrand(brand);
                        setSelBrand({
                          brand,
                          img,
                          descriptionEng,
                          descriptionChinese,
                        });
                      }}
                    >
                      <img className="brandImage" src={img} alt="brand-logo" />
                    </div>
                  )
                );
              }
            )}
          </div>
        </div>
        <div
          className="background"
          style={{ backgroundImage: `url(${dv})` }}
        ></div>
        <div className="origin" style={{ backgroundColor: "white" }}>
          <h3 className="country">
            {isChinese ? "多明尼加雪茄品牌" : "Dominican Cigars"}
          </h3>
          <div className="brands" data-aos="zoom-in">
            {productdata.map(
              ({ brand, img, origin, descriptionEng, descriptionChinese }) => {
                return (
                  origin.includes("dominica") && (
                    <div
                      className="brand"
                      onClick={() => {
                        showBrand(brand);
                        setSelBrand({
                          brand,
                          img,
                          descriptionEng,
                          descriptionChinese,
                        });
                      }}
                    >
                      <img className="brandImage" src={img} alt="brand-logo" />
                    </div>
                  )
                );
              }
            )}
          </div>
          <h3 className="country">
            {isChinese ? "洪都拉斯雪茄品牌" : "Honduran Cigars"}
          </h3>
          <div className="brands" data-aos="zoom-in">
            {productdata.map(
              ({ brand, img, descriptionEng, origin, descriptionChinese }) => {
                return (
                  origin.includes("honduras") && (
                    <div
                      className="brand"
                      onClick={() => {
                        showBrand(brand);
                        setSelBrand({
                          brand,
                          img,
                          descriptionEng,
                          descriptionChinese,
                        });
                      }}
                    >
                      <img className="brandImage" src={img} alt="brand-logo" />
                    </div>
                  )
                );
              }
            )}
          </div>
        </div>
        <div
          className="background"
          style={{ backgroundImage: `url(${ca})`, backgroundPosition: "left" }}
          ref={refWine}
        ></div>
        <div className="wineDescription">
          <div className="wineDescriptionBorder">
            <div className="wineLogo">
              <img src={caLogo} alt="brand-logo" />
            </div>
            <div className="wineItems">
              <div className="wineItem" data-aos="fade-right">
                <img className="wineImage" src={caRr} alt="wine" />
                <div className="wineTastingNotes">
                  {isChinese ? (
                    <h2>高希霸魅力紅酒 Reserva</h2>
                  ) : (
                    <h2>Cohiba Atmosphere Rioja Reserva</h2>
                  )}
                  {isChinese ? <h5>顏色</h5> : <h5>Colour</h5>}
                  {isChinese ? (
                    <p>呈紅寶石顏色，清澈、深厚而亮麗。</p>
                  ) : (
                    <p>Red ruby colour. Clean, intense and shiny.</p>
                  )}
                  {isChinese ? <h5>聞香</h5> : <h5>Nose</h5>}
                  {isChinese ? (
                    <p>
                      因土釀關係，蘊藏複雜動人的香氣，包括：黑色莓果、香草、巧克力和礦物氣息。
                    </p>
                  ) : (
                    <p>
                      Complex and aromatic nose with blackfruit, vanilla,
                      chocolate and mineral due to its terroir.
                    </p>
                  )}
                  {isChinese ? <h5>口感</h5> : <h5>Taste</h5>}
                  {isChinese ? (
                    <p>
                      味道結構良好，新鮮悠長，美味而細滑。口感極好，水果和橡木的香氣在其酒體中出色地融合在一起。
                    </p>
                  ) : (
                    <p>
                      Well structured, fresh, long, tasty and unctuous.
                      Excellence in mouth. Well integrated scent of fruit and
                      oak in its body.
                    </p>
                  )}
                  <hr />
                  {isChinese ? <h5>葡萄品種</h5> : <h5>Variety of grape</h5>}
                  {isChinese ? (
                    <p>
                      80% Tempranillo, 10% Graciano, 7% Mazuelo, 3% Viura.
                      人手採摘收成
                    </p>
                  ) : (
                    <p>
                      80% Tempranillo, 10% Graciano, 7% Mazuelo, 3% Viura.
                      Hand-picked harvesting.
                    </p>
                  )}
                  <hr />
                  {isChinese ? <h5>葡萄莊園</h5> : <h5>Estate</h5>}
                  <p>Cantoblanco. Rioja Alta.</p>
                  {isChinese ? (
                    <p>氣候：大西洋氣候</p>
                  ) : (
                    <p>Climate: Atlantic.</p>
                  )}
                  {isChinese ? (
                    <p>土壤: Clayey-calcareous.</p>
                  ) : (
                    <p>Soil: Clayey-calcareous.</p>
                  )}
                  <hr />
                  <div>
                    {isChinese ? <h5>陳釀</h5> : <h5>Aging</h5>}
                    {isChinese ? (
                      <p>橡木桶陳年: 法國橡木桶 18個月</p>
                    ) : (
                      <p>Barrel : 18 months in French Oak</p>
                    )}
                    {isChinese ? (
                      <p>酒瓶陳年: 18個月</p>
                    ) : (
                      <p>Bottle: 18 months </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="wineItem" data-aos="fade-left">
                <img className="wineImage" src={caGr} alt="wine" />
                <div className="wineTastingNotes">
                  {isChinese ? (
                    <h2>高希霸魅力紅酒 Gran Reserva</h2>
                  ) : (
                    <h2>Cohiba Atmosphere Rioja Gran Reserva</h2>
                  )}
                  {isChinese ? <h5>顏色</h5> : <h5>Colour</h5>}
                  {isChinese ? (
                    <p>黑櫻桃般的深紅色，深厚而富有光澤。</p>
                  ) : (
                    <p>Dark cherry colour. Intense and bright.</p>
                  )}
                  {isChinese ? <h5>聞香</h5> : <h5>Nose</h5>}
                  {isChinese ? (
                    <p>優雅及複的香氣，帶有輕輕的香草及輕烤熟果芳香。</p>
                  ) : (
                    <p>
                      Elegant and complex aromas with a touch of vanilla and
                      ripe fruits lightly toasted.
                    </p>
                  )}
                  {isChinese ? <h5>口感</h5> : <h5>Taste</h5>}
                  {isChinese ? (
                    <p>口感寛廣強勁，均勻而優雅，微妙而柔順。</p>
                  ) : (
                    <p>
                      Broad and powerful in mouth, with great balance and
                      elegance. Subtle and velvety
                    </p>
                  )}
                  <hr />
                  {isChinese ? <h5>葡萄品種</h5> : <h5>Variety of grape</h5>}
                  {isChinese ? (
                    <p>
                      90% Tempranillo, 7% Graciano and 3% Viura. 人手採摘收成。
                    </p>
                  ) : (
                    <p>
                      90% Tempranillo, 7% Graciano and 3% Viura. Hand-picked
                      harvesting.
                    </p>
                  )}
                  <hr />
                  <div className="soil-and-age">
                    <div>
                      {isChinese ? <h5>葡萄莊園</h5> : <h5>Estate</h5>}
                      <p>Fuenmayor, Rioja Alta.</p>
                      {isChinese ? (
                        <p>氣候：大西洋氣候</p>
                      ) : (
                        <p>Climate: Atlantic.</p>
                      )}
                      {isChinese ? (
                        <p>土壤： Clay-ferrous.</p>
                      ) : (
                        <p>Soil: Clay-ferrous.</p>
                      )}
                    </div>
                    <hr />
                    <div>
                      {isChinese ? <h5>陳釀</h5> : <h5>Aging</h5>}
                      {isChinese ? (
                        <p>橡木桶陳年: 法國-美國橡木桶 30個月</p>
                      ) : (
                        <p>Barrel : 30 months in French-American Oak</p>
                      )}
                      {isChinese ? (
                        <p>酒瓶陳年: 36個月</p>
                      ) : (
                        <p>Bottle: 36 months </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Products;
