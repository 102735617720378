import ProductModal from "../ProductModal/ProductModal";
import "./ArticleDetail.css";
import { useOutletContext } from "react-router-dom";

const ArticleContent = (props) => {
  return props.contents.map((content) => {
    return (
      <div>
        {content} <br /> <br />
      </div>
    );
  });
};

const ArticleDetail = (props) => {
  const [language] = useOutletContext();
  const isChinese = language === "chinese";
  const image = props.article.thumbnail;

  return (
    <>
      <ProductModal onExit={props.onExit}>
        <div className="article-container">
          <h2 className="article-title">
            {isChinese ? props.article.title[0] : props.article.title[1]}
          </h2>
          <div className="article-date">
            {props.article.year}/{props.article.month}/{props.article.day}
          </div>
          <div className="article-content-container">
            <div className="article-img">
              <img src={image} alt="article-img" />
            </div>
            <div className="article-content">
              {isChinese ? (
                <ArticleContent contents={props.article.content[0]} />
              ) : (
                <ArticleContent contents={props.article.content[1]} />
              )}
            </div>
          </div>
        </div>
      </ProductModal>
    </>
  );
};

export default ArticleDetail;
