import "./BrandDetail.css";
import { useOutletContext } from "react-router-dom";

const BrandDetail = (props) => {
  const [language] = useOutletContext();
  const isChinese = language === "chinese";
  return (
    <div className="brandDetail">
      <div className="brandDetailImage">
        <img src={props.product.img} alt="brand-logo" />
      </div>
      <h3 className="brandDetailTitle">{props.product.brand}</h3>
      <div className="brandDetailDescription">
        {isChinese
          ? props.product.descriptionChinese
          : props.product.descriptionEng}
      </div>
    </div>
  );
};

export default BrandDetail;
