import "./About.css";
import cover from "../assets/cover.jpeg";
import Footer from "../components/Footer/Footer";
import { useOutletContext } from "react-router-dom";

const About = () => {
  const [language] = useOutletContext();
  const isChinese = language === "chinese";
  return (
    <div>
      <div
        className="about-container"
        style={{ backgroundImage: `url(${cover})` }}
      >
        <div className="about-bg">
          <div className="about-box">
            <div className="about-content">
              <div className="about-us">
                <h4>{isChinese ? "關於我們" : "About Us"}</h4>
              </div>
              {isChinese && (
                <p className="about-description">
                  新信威煙業貿易有限公司早在1997年創立，作為古巴雪茄澳門總代理，在澳門服務雪茄愛好者已有二十多年的歷史。
                  <br />
                  <br />
                  新信威代理的古巴雪茄有二十多個品牌，除了古巴品牌外，我們亦提供古巴以外的雪茄品牌，使更多的雪茄愛好者能品嘗更多不同牌子、不同尺寸、及不同產地的雪茄，讓雪茄愛好者有更豐富的選擇。
                  <br />
                  <br />
                  除了雪茄外，新信威亦出售各國紅酒洋酒、雪茄配件、煙斗及名貴香煙，讓顧客除了品嘗雪茄之外亦可同時配搭或嘗試不同產品。
                </p>
              )}
              {!isChinese && (
                <p className="about-description">
                  San Son Wai Cigars Trading Company Limited was founded in 1997
                  as the exclusive distributor of Cuban Cigars in Macau and
                  served cigar aficionados for more than 20 years in Macau.{" "}
                  <br />
                  <br />
                  Aside from all Cuban cigar brands offered by San Son Wai
                  Cigars Trading Company, cigars from different countries are
                  also specially selected in order to offer cigar aficionados in
                  Macau a broader cigar selection with different sizes,
                  different brands, and cigars from different countries.
                  <br />
                  <br />
                  Cigar accesssories, wine and spirits, pipe and pipe tobacco
                  are also offered to aficionados with special interests.
                </p>
              )}
              {isChinese ? (
                <p className="contactus">
                  如需要更多資訊，歡迎聯絡我們。電郵：info@ssw.com.mo
                </p>
              ) : (
                <p className="contactus">
                  Want to learn more? Feel free to contact us.
                  E-mail：info@ssw.com.mo
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
