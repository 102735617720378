import "./Header.css";
import logo from "../../assets/SSW-Logo.png";
import { useState, useEffect } from "react";
import VerifyAge from "../VerifyAge/VerifyAge";
import { Outlet, NavLink } from "react-router-dom";
import { TfiWorld } from "react-icons/tfi";
import ScrollToTop from "../ScrollToTop";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdCloseCircle } from "react-icons/io";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Menu = (props) => {
  return (
    <ul>
      <li>
        <NavLink
          to=""
          className={({ isActive }) => (isActive ? "activeNav" : "nav")}
        >
          {props.isChinese ? "首頁" : "Home"}
        </NavLink>
      </li>
      <li>
        <NavLink
          to="products"
          className={({ isActive }) => (isActive ? "activeNav" : "nav")}
        >
          {props.isChinese ? "品牌產品" : "Products"}
        </NavLink>
      </li>
      <li>
        <NavLink
          to="shops"
          className={({ isActive }) => (isActive ? "activeNav" : "nav")}
        >
          {props.isChinese ? "店舖位置" : "Locations"}
        </NavLink>
      </li>
      <li>
        <NavLink
          to="aboutus"
          className={({ isActive }) => (isActive ? "activeNav" : "nav")}
        >
          {props.isChinese ? "關於我們" : "About Us"}{" "}
        </NavLink>
      </li>
    </ul>
  );
};

const Header = (props) => {
  const [ageConfirm, setAgeConfirm] = useState(false);
  const language = props.lang;
  const [showMenu, setShowMenu] = useState(false);
  const [showDropbox, setShowDropbox] = useState(false);
  const isChinese = language === "chinese";
  const { pathname } = useLocation();

  const toggleDropbox = () => {
    setShowDropbox(!showDropbox);
  };

  const languageHandler = (lang) => {
    if (isChinese) {
      document.title = "新信威煙業貿易有限公司";
    } else {
      document.title = "San Son Wai Cigars Trading Company Limited";
    }
    if (language !== lang) {
      if (lang === "chinese") {
        window.location.href = "/zh" + pathname;
      } else {
        if (pathname === "/zh") {
          //for relative path of "/zh"
          window.location.href = "/";
        } else {
          const firstSlash = pathname.substring(1).indexOf("/");
          window.location.href = pathname.substring(firstSlash + 1);
        }
      }
    }
  };

  const confirmAgeHandler = () => {
    sessionStorage.setItem("isAgeConfirmed", "1");
    setAgeConfirm(true);
  };

  useEffect(() => {
    const isAgeConfirmed = sessionStorage.getItem("isAgeConfirmed");
    if (isAgeConfirmed) {
      setAgeConfirm(true);
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 500, once: false });
    AOS.refresh();
  }, []);

  const [isPageTop, setIsPageTop] = useState(true);
  useEffect(() => {
    function onScroll() {
      if (!isPageTop) {
        if (window.pageYOffset === 0) {
          setIsPageTop(true);
        }
      } else {
        if (window.pageYOffset !== 0) {
          setIsPageTop(false);
        }
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [isPageTop]);
  return (
    <>
      <ScrollToTop />
      <div>
        {showMenu && (
          <div
            className="hidden-menu"
            data-aos="fade-up"
            data-aos-duration="500"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <Menu isChinese={isChinese} />
            <div className="close-btn">
              <IoMdCloseCircle />
            </div>
          </div>
        )}
      </div>
      <header className={`header ${isPageTop ? "istop" : "nottop"}`}>
        {!ageConfirm && <VerifyAge onConfirm={confirmAgeHandler} />}
        <div className="logo">
          <NavLink to="/">
            <img src={logo} alt="SSW Logo" />
          </NavLink>
        </div>
        <div className="menu">
          <Menu isChinese={isChinese} />
          <div className="small-menu" onClick={() => setShowMenu(true)}>
            <GiHamburgerMenu size={22} />
          </div>
        </div>
        <div className="language">
          <div className="language-block">
            <div className="language-block-left">
              <TfiWorld />
            </div>
            <div
              className="language-block-right"
              onClick={() => toggleDropbox()}
            >
              <div>{isChinese ? "中文" : "English"}</div>
              <div
                className={`language-dropbox ${
                  showDropbox ? "visibile" : "hidden"
                }`}
              >
                <div
                  onClick={() => languageHandler("chinese")}
                  className={language === "chinese" ? "activeLanguage" : ""}
                >
                  中文
                </div>
                <div
                  onClick={() => languageHandler("english")}
                  className={language === "english" ? "activeLanguage" : ""}
                >
                  English
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Outlet context={[language, ageConfirm]} />
    </>
  );
};

export default Header;
