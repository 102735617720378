import "./Article.css";
import { useOutletContext } from "react-router-dom";

const Article = (props) => {
  const article = props.article;
  const thumbnail = article.thumbnail;
  const [language] = useOutletContext();
  const isChinese = language === "chinese";

  return (
    <div className="article" style={{ backgroundImage: `url(${thumbnail})` }}>
      <div className="article-header">
        <div className="title" onClick={props.onClickHandler}>
          {isChinese ? article.title[0] : article.title[1]}
        </div>
        <div className="date">
          {article.year}/{article.month}/{article.day}
        </div>
      </div>
    </div>
  );
};

export default Article;
